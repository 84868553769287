<template>
<div>
    <v-menu offset-y>
        <template v-slot:activator="{ on }">
            <v-btn x-large="" text v-on="on" :color="$vuetify.theme.dark ? 'white' : 'third'" >
                <!-- <v-icon class="hidden-sm-and-down">fi fi-rr-portrait</v-icon> -->
                <v-avatar rounded border>
                    <v-img :src="$store.state.user.photoUrl == null ? require('@/assets/images/photo.svg') : $url + $store.state.user.photoUrl"/>
                </v-avatar>
                <!-- <h3 class="mx-4">{{$store.state.user.userInfo.fullName != null ? $store.state.user.userInfo.fullName : 'Null'}}</h3> -->
                <!-- <h3 class="mx-4">{{$store.state.user == null ? 'غير معرف' :$store.state.user.userData.fullName}}</h3> -->
                <h3 class="mx-4">{{ $store.state.user.fullName }}</h3>
                <v-icon class="hidden-sm-and-down">keyboard_arrow_down</v-icon>
            </v-btn>
            
        </template>
        
        <v-list dark >

            <!-- <v-list-item v-if="$store.state.user.organizationInfo">
                <v-list-item-title ><strong>{{$store.state.user.organizationInfo.name}}</strong></v-list-item-title>
            </v-list-item> -->
<!-- 
            <v-divider/>

            <v-list-item >
                <v-list-item-title ><strong>سمة الألوان :</strong></v-list-item-title>
                <themeChanger/>
                
            </v-list-item> -->

            <!-- <v-divider/> -->

            <v-list-item @click="logout()">
                <v-list-item-title class="red--text"><strong>تسجيل خروج</strong></v-list-item-title>
                <v-list-item-icon>
                    <v-icon class="red--text">power_settings_new</v-icon>
                </v-list-item-icon>
            </v-list-item>

        </v-list>
    </v-menu>
</div>
</template>

<script>
export default {

    components:{
        // themeChanger: ()=> import('./themeChanger')
    },

    methods: {
        logout() {
            localStorage.removeItem("token");
            this.$router.push('/login');
        },
    }

    // created() {
    //     this.$eventBus.$on('user', (data) => {
    //         this.user = data.user
    //         // // console.log(data.user.name)
    //     });
    //     this.user = JSON.parse(localStorage.getItem('user'))
    //     // // console.log(this.user)
    // },
}
</script>
